import { ButtonProps } from 'primereact/button';

export interface Action extends ButtonProps {
    command?: Function | void;
    form?: string;
    url?: string;
}

export interface RMButtonProps extends ButtonProps {
    icon: string;
}

export enum INPUT_TYPES {
    button = 'button',
    checkbox = 'checkbox',
    color = 'color',
    date = 'date',
    datetimeLocal = 'datetime-local',
    email = 'email',
    file = 'file',
    image = 'image',
    month = 'month',
    number = 'number',
    password = 'password',
    radio = 'radio',
    reset = 'reset',
    search = 'search',
    submit = 'submit',
    tel = 'tel',
    text = 'text',
    url = 'url',
    week = 'week',
}

export enum ERROR_TYPES {
    pageNotFound = 'page-not-found',
    maintenance = 'maintenance',
    notFound = 'not-found',
    fileError = 'file-error',
    empty = 'empty',
    emptyFolder = 'empty-folder',
    inboxEmpty = 'inbox-empty',
    somethingsWrong = 'somethings-wrong',
}

/**
 * Entity types
 */
export enum BaseEntityType {
    users = 'users',
    teams = 'teams',
    organisations = 'organisations',
    associations = 'associations',
}

/**
 * Entity types
 */
export enum EntityType {
    users = 'users',
    teams = 'teams',
    players = 'players',
    organisations = 'organisations',
    associations = 'associations',
    competitions = 'competitions',
    leagues = 'leagues',
    seasons = 'seasons',
    grades = 'grades',
    clubs = 'clubs',
    games = 'games',
    events = 'events',
    usersDefault = 'usersDefault',
    posts = 'posts',
}

export enum UserType {
    entityPlayer = 'entityPlayer',
    entityStaff = 'entityStaff',
    entityGuardian = 'entityGuardian',
}

export interface EntityStructure {
    entityType: BaseEntityType;
    entityID: string;
}

export interface TabItem {
    id: string;
    title: string;
    component: any;
}
