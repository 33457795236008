import { useContext } from 'react';
import { Formik } from 'formik';
import { pick } from 'lodash';

import { ToastContext } from '../../contexts/ToastContext';

import { ProgressBar } from 'primereact/progressbar';
import { ToastMessage } from 'primereact/toast';

import FormGroup from '../../components/FormGroup';
import FormActions from '../../components/FormActions';
import RookieButton from '../../components/RookieButton';

import { Mixpanel } from '../../util/mixpanel';
import { ChannelsEnabledOptions, MessageSettings, MessageSettingsFormData } from '../../types/messages';
import { InputSwitch } from 'primereact/inputswitch';
import { useCreateMessageSettingsMutation, useUpdateMessageSettingsMutation } from '../../api/messages';
import { BaseEntityType } from '../../types/common';
import { useNavigate } from 'react-router-dom';
import FormFields from '../../components/FormFields';

interface Props {
    messageSettings?: MessageSettings;
    entityType: BaseEntityType;
    entityID: string;
}

const availableFields: Array<keyof MessageSettingsFormData> = ['enabled', 'moderators', 'channelsEnabled'];

const MessageSettingsForm = (props: Props) => {
    const {
        messageSettings,
        entityID,
        entityType
    } = props;

    const isCreate = !messageSettings;

    const useUpsertMessageSettings = isCreate
        ? useCreateMessageSettingsMutation
        : useUpdateMessageSettingsMutation;

    const [upsertMessageSettings] = useUpsertMessageSettings();

    const toast = useContext(ToastContext);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };
    const navigate = useNavigate();

    const handleSubmit = (data: MessageSettingsFormData) => {
        console.log(`Submitting data`, data);
        
        if (entityID) {
            upsertMessageSettings({
                entityID,
                entityType,
                ...data,
            })
                .then((response) => {
                    // @ts-ignore
                    if( response?.error ) {
                        // @ts-ignore
                        throw new Error(response.error.data.message);
                    }

                    showToast({
                        severity: 'success',
                        summary: 'Success',
                        detail: `Updated Settings, please allow up to 30 seconds for changes to reflect.`,
                    });

                    Mixpanel.track(`${isCreate ? 'Create' : 'Update'} Message Settings`);
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: `Could not update settings.`,
                    });
                }).finally( () => {
                    navigate(0); // refresh
                });
        }
    };

    const defaultProps = {
            enabled: true,
            moderators: [],
            channelsEnabled: {
                groups: true,
                players: false,
                guardians: false,
                staff: true,
                everybody: true
            }
    };

    const initialValues = {
        ...pick(defaultProps, availableFields),
         ...pick(messageSettings, availableFields)
    };

    return (
        <Formik
            enableReinitialize
            // @ts-ignore
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                values,
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormFields>
                            <FormGroup label="Enable Messaging" htmlFor="enabled">
                                <InputSwitch
                                    id="enabled"
                                    name="enabled"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={!!values.enabled}
                                    required
                                />
                            </FormGroup>
                            <FormGroup label={'Enabled Channels'} htmlFor={'channelsEnabled'} key={'channelsEnabled'}>
                            { 
                            Object.entries(ChannelsEnabledOptions).map( ([label, key]) => {
                                const id = `channelsEnabled.${key}`;
                                return (
                                    <>
                                    <label htmlFor={id}>{label}</label>
                                    <InputSwitch
                                        id={id}
                                        key={id + 'Input'}
                                        name={label}
                                        // @ts-ignore - sorry phil
                                        checked={Object.entries(values.channelsEnabled).some(
                                                      (
                                                          [chanKey, chanValue]
                                                      ) =>
                                                        chanKey === key as string && !!chanValue
                                                  )
                                        }
                                        // onChange={(event=>{return console.log(event);})}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                    />
                                    </>
                                )
                            })
                            }
                                                            </FormGroup> 

                        </FormFields>
                        {/* @todo - chuck in a multiselect user selection here for `moderators` */}
                        <FormActions
                            end={
                                <RookieButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    label={
                                        isCreate
                                            ? 'Create Message Settings'
                                            : 'Update Message Settings'
                                    }
                                />
                            }
                        />
                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};


export default MessageSettingsForm;
