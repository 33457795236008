import { useNavigate, useSearchParams } from 'react-router-dom';
import ResourcesView from './ResourcesView';
import { TabItem } from '../../types/common';
import ResourceFiles from './ResourceFiles';
import ResourceLinks from './ResourceLinks';
import { TabViewTabChangeEvent } from 'primereact/tabview';
import { useEffect } from 'react';

const ResourceContainer = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTabChange = (e: TabViewTabChangeEvent) => {
        setSearchParams({ tab: tabs[e.index].id });
    };

    const tab = searchParams.get('tab');

    const tabs: TabItem[] = [
        {
            id: 'files',
            title: 'Files',
            component: ResourceFiles,
        },
        {
            id: 'links',
            title: 'Links',
            component: ResourceLinks,
        },
    ];

    const activeTabIndex = tabs.findIndex(
        (tab: TabItem) => searchParams.get('tab') === tab.id
    );

    useEffect(() => {
        // Handle redirection to "files"
        if (!tab || !['files', 'links'].includes(tab)) {
            navigate(
                {
                    search: '?tab=files',
                },
                {
                    replace: true,
                }
            );
        }
    }, [navigate, tab]);

    return (
        <ResourcesView
            activeTabIndex={activeTabIndex}
            tabs={tabs}
            onTabChange={handleTabChange}
        />
    );
};

export default ResourceContainer;
