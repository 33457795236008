import {
    Channel,
    ChannelHeader,
    ChannelList,
    Chat,
    LoadingIndicator,
    MessageInput,
    MessageList,
    Thread,
    useCreateChatClient,
    Window,
} from 'stream-chat-react';

import 'stream-chat-react/dist/css/v2/index.css';

interface Props {
    userData: any;
    streamToken: any;
    filters: any;
    sort: any;
    options: any;
}

const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY || '';

const MessagesView = (props: Props) => {

    let chatClient = useCreateChatClient({
        apiKey,
        tokenOrProvider: props.streamToken,
        userData: props.userData,
      });

    if (!chatClient) {
        return <LoadingIndicator />;
    }

    return (
        <div className="stream-chat">
            <Chat client={chatClient} theme="str-chat__theme-light">
                <ChannelList
                    filters={props.filters}
                    sort={props.sort}
                    options={props.options}
                />
                <Channel>
                    <Window>
                        <ChannelHeader />
                        <MessageList />
                        <MessageInput />
                    </Window>
                    <Thread />
                </Channel>
            </Chat>
        </div>
    );
};

export default MessagesView;
