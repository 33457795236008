import { Tree, TreeEventNodeEvent } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import { useEffect, useMemo, useState } from 'react';
import { useGetSportResourceLinksQuery } from '../../api/resources';
import { useGetEntityQuery } from '../../api/core';
import { getEntityFromParam } from '../../util/helper';
import { useParams } from 'react-router-dom';
import { BaseEntityType } from '../../types/common';

const ResourceLinks = () => {
    const params = useParams();

    const [treeData, setTreeData] = useState<TreeNode[]>([]);

    // Get active entity id and type
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const { data: entityData } = useGetEntityQuery({
        entityType: activeEntity?.entityType as BaseEntityType,
        entityID: activeEntity?.entityID as string,
    });

    const entitySportID = useMemo(
        () => entityData?.data.entitySportID,
        [entityData]
    );

    const { data: linkData } = useGetSportResourceLinksQuery(
        {
            sportID: entitySportID as string,
        },
        { skip: !entitySportID }
    );

    // Helper function to group links by category and subcategory
    const buildTreeData = (links: any[]): TreeNode[] => {
        const tree: { [category: string]: TreeNode } = {};

        links.forEach((link) => {
            const category = link.linkCategory || 'Uncategorized';
            const subCategory = link.linkSubCategory || 'General';

            // If the category doesn't exist in the tree, create it
            if (!tree[category]) {
                tree[category] = {
                    key: category,
                    label: category,
                    children: [],
                };
            }

            // Find or create the subcategory under the current category
            let subCatNode = tree[category].children?.find(
                (child) => child.label === subCategory
            );

            if (!subCatNode) {
                subCatNode = {
                    key: `${category}-${subCategory}`,
                    label: subCategory,
                    children: [],
                };
                tree[category].children?.push(subCatNode);
            }

            // Add the link as a leaf node under the subcategory
            subCatNode.children?.push({
                key: link.linkID,
                label: link.linkName,
                data: {
                    url: link.linkURL,
                },
            });
        });

        // Convert the object into an array of TreeNodes
        return Object.values(tree);
    };

    // Update treeData when entityData is fetched
    useEffect(() => {
        if (linkData?.data) {
            const groupedTreeData = buildTreeData(linkData.data);
            setTreeData(groupedTreeData);
        }
    }, [linkData]);

    const handleNodeSelect = (event: TreeEventNodeEvent) => {
        const node = event.node as TreeNode;

        if (node?.data?.url) {
            window.open(node.data.url, '_blank'); // Open URL in a new tab
        }
    };

    return (
        <Tree
            value={treeData}
            onSelect={handleNodeSelect}
            dragdropScope="demo"
            selectionMode="single"
            filter
            filterMode="lenient"
            filterPlaceholder="Search..."
        />
    );
};

export default ResourceLinks;
