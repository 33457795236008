import { useState } from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';

import {
    useCreateResourceFolderMutation,
    useUpdateResourceFolderMutation,
} from '../../api/resources';

import { InputText } from 'primereact/inputtext';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';
import FormActions from '../../components/FormActions';

import { BaseEntityType } from '../../types/common';
import {
    ResourceFolder,
    ResourceFolderForm,
    ResourceFolderResponse,
} from '../../types/resources';

interface Props {
    parentFolderID?: string;
    folder?: ResourceFolder;
    entityID?: string;
    entityType?: BaseEntityType;
    onError?: (error: any) => void;
    onSuccess?: (response: ResourceFolderResponse) => void;
}

const FolderForm = (props: Props) => {
    const { folder, parentFolderID, onError, onSuccess } = props;

    const [loading, setLoading] = useState(false);

    const isUpdating = !!folder?.folderID;

    const [createFolder] = useCreateResourceFolderMutation();
    const [updateFolder] = useUpdateResourceFolderMutation();

    const upsertFolder = isUpdating ? updateFolder : createFolder;

    const handleSubmit = (data: ResourceFolderForm) => {
        if (props.entityType && props.entityID) {
            setLoading(true);

            upsertFolder({
                entityType: props.entityType,
                entityID: props.entityID,
                parentID: isUpdating ? undefined : parentFolderID,
                folderID:
                    isUpdating && folder?.folderID
                        ? folder.folderID
                        : undefined,
                ...data,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response.error);
                        }
                    } else {
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<ResourceFolderForm> = {};

        if (!values.folderName || values.folderName.trim() === '') {
            errors.folderName = 'Field cannot be blank';
        }

        return errors;
    };

    return (
        <div>
            <Formik
                initialValues={{
                    folderName: folder?.folderName || '',
                }}
                onSubmit={handleSubmit}
                validate={validate}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormFields
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <FormGroup
                                    label="Name"
                                    htmlFor="folderName"
                                    error={errors.folderName}
                                    showError={
                                        !!errors.folderName &&
                                        touched.folderName
                                    }
                                >
                                    <InputText
                                        id="folderName"
                                        name="folderName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Folder Name"
                                        value={values.folderName}
                                        autoFocus
                                        disabled={loading}
                                    />
                                </FormGroup>
                            </FormFields>
                            <FormActions
                                end={
                                    <RookieButton
                                        type="submit"
                                        label={isUpdating ? 'Save' : 'Create'}
                                        disabled={
                                            loading || values.folderName === ''
                                        }
                                        loading={loading}
                                    />
                                }
                            />
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default FolderForm;
