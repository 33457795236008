import { USE_CUSTOM_TOKEN, CUSTOM_TOKEN_ID } from '../util/constants';
import { sec } from '../util/security';

export const apiEndpoints = {
    /**
     *
     * Core
     *
     **/

    getMe: {
        url: '/me',
        method: 'GET',
    },
    getUserDetails: {
        url: '/users/:userID/userDetails',
        method: 'GET',
    },
    getRoles: {
        url: '/users/:userID/roles',
        method: 'GET',
    },
    getEntityRoles: {
        url: '/:entityType/:entityID/roles',
        method: 'GET',
    },
    getEntity: {
        url: '/:entityType/:entityID',
        method: 'GET',
    },
    getEntityKit: {
        url: '/:entityType/:entityID/kit',
        method: 'GET',
    },
    updateEntityKit: {
        url: '/:entityType/:entityID/kit',
        method: 'PUT',
    },
    getDesignOptions: {
        url: '/design',
        method: 'GET',
    },
    transferEntityOwnership: {
        url: '/:entityType/:entityID/owner',
        method: 'PUT',
    },
    getTeamGrade: {
        url: '/:sports/:$sportID/entityCategories',
        method: 'GET',
    },

    /**
     *
     * Associations
     *
     **/

    getAssociation: {
        url: '/associations/:associationID',
        method: 'GET',
    },
    createAssociation: {
        url: '/users/:userID/associations',
        method: 'POST',
    },
    updateAssociation: {
        url: '/associations/:associationID',
        method: 'PUT',
    },
    deleteAssociation: {
        url: '/associations/:associationID',
        method: 'DELETE',
    },

    // Association Teams
    getAssociationTeams: {
        url: '/associations/:associationID/teams',
        method: 'GET',
    },
    createAssociationTeam: {
        url: '/associations/:associationID/teams',
        method: 'POST',
    },
    updateAssociationTeam: {
        url: '/associations/:associationID/teams/:teamID',
        method: 'PUT',
    },
    deleteAssociationTeam: {
        url: '/associations/:associationID/teams/:teamID',
        method: 'DELETE',
    },

    // Association Organisations
    getAssociationOrganisations: {
        url: '/associations/:associationID/organisations',
        method: 'GET',
    },
    createAssociationOrganisation: {
        url: '/associations/:associationID/organisations/:organisationID',
        method: 'POST',
    },
    updateAssociationOrganisation: {
        url: '/associations/:associationID/organisations/:organisationID',
        method: 'PUT',
    },
    deleteAssociationOrganisation: {
        url: '/associations/:associationID/organisations/:organisationID',
        method: 'DELETE',
    },

    /**
     *
     * Collections
     *
     **/

    getCollections: {
        url: '/:entityType/:entityID/collections',
        method: 'GET',
    },
    getCollection: {
        url: '/:entityType/:entityID/collections/:collectionID',
        method: 'GET',
    },
    getCollectionTeams: {
        url: '/:entityType/:entityID/collections/:collectionID/teams',
        method: 'GET',
    },
    createCollection: {
        url: '/:entityType/:entityID/collections',
        method: 'POST',
    },
    updateCollection: {
        url: '/:entityType/:entityID/collections/:collectionID',
        method: 'PUT',
    },
    deleteCollection: {
        url: '/:entityType/:entityID/collections/:collectionID',
        method: 'DELETE',
    },
    addTeamToCollection: {
        url: '/:entityType/:entityID/collections/:collectionID/teams',
        method: 'POST',
    },
    removeTeamFromCollection: {
        url: '/:entityType/:entityID/collections/:collectionID/teams/:teamID',
        method: 'DELETE',
    },

    /**
     *
     * Events
     *
     **/

    getEvent: {
        url: '/:entityType/:entityID/events/:eventID',
        method: 'GET',
    },
    getEvents: {
        url: '/:entityType/:entityID/events',
        method: 'GET',
    },
    getSeasonEvents: {
        url: '/:entityType/:entityID/seasons/:seasonID/events',
        method: 'GET',
    },
    createEvent: {
        url: '/:entityType/:entityID/events',
        method: 'POST',
    },
    updateEvent: {
        url: '/:entityType/:entityID/events/:eventID',
        method: 'PUT',
    },
    deleteEvent: {
        url: '/:entityType/:entityID/events/:eventID',
        method: 'DELETE',
    },
    createEventInvitee: {
        url: '/:entityType/:entityID/events/:eventID/invitees',
        method: 'POST',
    },
    deleteEventInvitee: {
        url: '/:entityType/:entityID/events/:eventID/invitees/:inviteeID',
        method: 'DELETE',
    },

    /**
     *
     * Games
     *
     **/

    getGameRoles: {
        url: '/teams/:teamID/games/:eventID/gameRoles',
        method: 'GET',
    },

    /**
     *
     * Groups
     *
     **/

    getGroups: {
        url: '/:entityType/:entityID/groups',
        method: 'GET',
    },
    createGroup: {
        url: '/:entityType/:entityID/groups',
        method: 'POST',
    },
    getGroup: {
        url: '/:entityType/:entityID/groups/:groupID',
        method: 'GET',
    },
    updateGroup: {
        url: '/:entityType/:entityID/groups/:groupID',
        method: 'PUT',
    },
    deleteGroup: {
        url: '/:entityType/:entityID/groups/:groupID',
        method: 'DELETE',
    },
    getGroupUsers: {
        url: '/:entityType/:entityID/groups/:groupID/users',
        method: 'GET',
    },
    addUserToGroup: {
        url: '/:entityType/:entityID/groups/:groupID/users',
        method: 'POST',
    },
    deleteUserFromGroup: {
        url: '/:entityType/:entityID/groups/:groupID/users/:userID',
        method: 'DELETE',
    },

    /**
     *
     * Licence and Subscriptions
     *
     **/

    getSubscriptions: {
        url: '/users/:userID/subscriptions',
        method: 'GET',
    },
    getSubscription: {
        url: '/users/:userID/subscriptions/:subscriptionID',
        method: 'GET',
    },
    getSubscriptionInvoices: {
        url: '/users/:userID/subscriptions/:subscriptionID/invoices',
        method: 'GET',
    },
    getInvoice: {
        url: '/users/:userID/invoices/:invoiceID',
        method: 'GET',
    },
    createBillingSession: {
        url: '/users/:userID/billing',
        method: 'POST',
    },
    cancelSubscription: {
        url: '/users/:userID/subscriptions/:subscriptionID',
        method: 'DELETE',
    },
    updateSubscription: {
        url: '/users/:userID/subscriptions/:subscriptionID',
        method: 'PUT',
    },
    getLicenceTypes: {
        url: '/licences/licenceTypes',
        method: 'GET', // EXPAND FLAGS = productDetails
    },
    getLicenceTypeFeatures: {
        url: '/licences/licenceTypes/:licenceTypeID/licenceFeatures',
        method: 'GET',
    },
    createCheckoutSession: {
        url: '/:entityType/:entityID/licences/checkout',
        method: 'POST',
    },
    getLicenceGroups: {
        url: '/:entityType/:entityID/licenceGroups',
        method: 'GET',
    },
    getLicenceGroup: {
        url: '/:entityType/:entityID/licenceGroups/:licenceGroupID',
        method: 'GET',
    },
    updateLicenceGroup: {
        url: '/:entityType/:entityID/licenceGroups/:licenceGroupID',
        method: 'PUT',
    },
    assignLicence: {
        url: '/:entityType/:entityID/licenceGroups/:licenceGroupID/licences',
        method: 'POST',
    },
    unAssignLicence: {
        url: '/:entityType/:entityID/licences/:licenceID',
        method: 'DELETE',
    },
    getLicenceGroupLicences: {
        url: '/:entityType/:entityID/licenceGroups/:licenceGroupID/licences',
        method: 'GET',
    },
    getTransferredLicenceGroups: {
        url: '/:entityType/:entityID/transferredLicenceGroups',
        method: 'GET',
    },
    getLicences: {
        url: '/:entityType/:entityID/licences',
        method: 'GET',
    },

    /**
     *
     * Notes
     *
     **/

    getNotes: {
        url: '/:entityType/:entityID/notes',
        method: 'GET',
    },
    createNote: {
        url: '/:entityType/:entityID/notes',
        method: 'POST',
    },
    updateNote: {
        url: '/:entityType/:entityID/notes/:noteID',
        method: 'PUT',
    },
    deleteNote: {
        url: '/:entityType/:entityID/notes/:noteID',
        method: 'DELETE',
    },
    getNote: {
        url: '/:entityType/:entityID/notes/:noteID',
        method: 'GET',
    },
    getNotesByNode: {
        url: '/:entityType/:entityID/notes/nodes/:nodeType/:nodeID',
        method: 'GET',
    },
    deleteNoteNode: {
        url: '/:entityType/:entityID/notes/:noteID/nodes/:nodeType/:nodeID',
        method: 'DELETE',
    },

    /**
     *
     * Notifications
     *
     **/

    getNotifications: {
        url: '/users/:userID/notifications',
        method: 'GET',
    },

    /**
     *
     * Messages
     *
     **/

    getMessageToken: {
        url: '/users/:userID/messageToken',
        method: 'GET',
    },
    getEntityChannels: {
        url: '/:entityType/:entityID/channels',
        method: 'GET',
    },
    getMessageSettings: {
        url: '/:entityType/:entityID/messageSettings',
        method: 'GET',
    },
    createMessageSettings: {
        url: '/:entityType/:entityID/messageSettings',
        method: 'POST',
    },
    updateMessageSettings: {
        url: '/:entityType/:entityID/messageSettings',
        method: 'PUT',
    },

    /**
     *
     * Organisations
     *
     **/

    getOrganisation: {
        url: '/organisations/:organisationID',
        method: 'GET',
    },
    createOrganisation: {
        url: '/users/:userID/organisations',
        method: 'POST',
    },
    updateOrganisation: {
        url: '/organisations/:organisationID',
        method: 'PUT',
    },
    deleteOrganisation: {
        url: '/organisations/:organisationID',
        method: 'DELETE',
    },

    // Organisation Teams
    getOrganisationTeams: {
        url: '/organisations/:organisationID/teams',
        method: 'GET',
    },
    createOrganisationTeam: {
        url: '/organisations/:organisationID/teams',
        method: 'POST',
    },
    updateOrganisationTeam: {
        url: '/organisations/:organisationID/teams/:teamID',
        method: 'PUT',
    },
    deleteOrganisationTeam: {
        url: '/organisations/:organisationID/teams/:teamID',
        method: 'DELETE',
    },
    archiveOrganisationTeam: {
        url: '/organisations/:organisationID/teams/:teamID',
        method: 'PUT',
    },

    /**
     *
     * Players
     *
     **/

    getPlayers: {
        url: '/teams/:teamID/players',
        method: 'GET',
    },
    getEntityPlayers: {
        url: '/teams/:teamID/entityPlayers',
        method: 'GET',
    },
    getPlayer: {
        url: '/teams/:teamID/players/:playerID',
        method: 'GET',
    },
    getPlayersByUser: {
        url: '/users/:userID/players',
        method: 'GET',
    },
    createPlayer: {
        url: '/teams/:teamID/players',
        method: 'POST',
    },
    updatePlayer: {
        url: '/teams/:teamID/players/:playerID',
        method: 'PUT',
    },
    deletePlayer: {
        url: '/teams/:teamID/players/:playerID',
        method: 'DELETE',
    },
    linkPlayerToUser: {
        url: '/teams/:teamID/players/:playerID/user',
        method: 'POST',
    },
    unlinkPlayerFromUser: {
        url: '/teams/:teamID/players/:playerID/user',
        method: 'DELETE',
    },

    /**
     *
     * Posts
     *
     **/

    getPosts: {
        url: '/:entityType/:entityID/posts',
        method: 'GET',
    },
    getPost: {
        url: '/:entityType/:entityID/posts/:postID',
        method: 'GET',
    },
    createPost: {
        url: '/:entityType/:entityID/posts',
        method: 'POST',
    },
    updatePost: {
        url: '/:entityType/:entityID/posts/:postID',
        method: 'PUT',
    },
    deletePost: {
        url: '/:entityType/:entityID/posts/:postID',
        method: 'DELETE',
    },
    createSharePost: {
        url: '/:entityType/:entityID/posts/:postID/share',
        method: 'POST',
    },

    /**
     *
     * Profiles
     *
     **/

    updateProfile: {
        url: '/users/:userID',
        method: 'PUT',
    },
    authProcessUpdateProfile: {
        url: '/authProcess/complete/users/:userID',
        method: 'PUT',
    },
    verifyEmail: {
        url: '/users/:userID/profiles/verify',
        method: 'POST',
    },

    /**
     *
     * Reports
     *
     **/

    getGameReport: {
        url: '/teams/:teamID/events/:eventID/reports/:reportType',
        method: 'GET',
    },
    getTeamSeasonReport: {
        url: '/teams/:teamID/seasons/:seasonID/reports/:reportType',
        method: 'GET',
    },

    /**
     *
     * Resources
     *
     **/

    createPutPresignedURL: {
        url: '/:entityType/:entityID/createPutPresignedURL',
        method: 'POST',
    },
    getSportResourceLinks: {
        url: '/sports/:sportID/links',
        method: 'GET',
    },
    getResourceFolders: {
        url: '/:entityType/:entityID/folders',
        method: 'GET',
    },
    createResourceFolder: {
        url: '/:entityType/:entityID/folders',
        method: 'POST',
    },
    createResourceFile: {
        url: '/:entityType/:entityID/resources',
        method: 'POST',
    },
    updateResourceFile: {
        url: '/:entityType/:entityID/resources/:resourceID',
        method: 'PUT',
    },
    deleteResourceFile: {
        url: '/:entityType/:entityID/resources/:resourceID',
        method: 'DELETE',
    },
    updateResourceFolder: {
        url: '/:entityType/:entityID/folders/:folderID',
        method: 'PUT',
    },
    deleteResourceFolder: {
        url: '/:entityType/:entityID/folders/:folderID',
        method: 'DELETE',
    },
    getResourceFolder: {
        url: '/:entityType/:entityID/folders/:folderID',
        method: 'GET',
    },

    /**
     *
     * Seasons
     *
     **/

    getTeamSeason: {
        url: '/teams/:teamID/seasons/:seasonID',
        method: 'GET',
    },
    getTeamOwnedSeasons: {
        url: '/teams/:teamID/seasons',
        method: 'GET',
    },
    getTeamParticipatingSeasons: {
        url: '/teams/:teamID/teamSeasons',
        method: 'GET',
    },
    createTeamSeason: {
        url: '/teams/:teamID/seasons',
        method: 'POST',
    },
    updateTeamSeason: {
        url: '/teams/:teamID/seasons/:seasonID',
        method: 'PUT',
    },
    archiveTeamSeason: {
        url: '/teams/:teamID/seasons/:seasonID',
        method: 'PUT',
    },
    deleteTeamSeason: {
        url: '/teams/:teamID/seasons/:seasonID',
        method: 'DELETE',
    },
    getAssociationSeasons: {
        url: '/associations/:associationID/seasons',
        method: 'GET',
    },
    getAssociationSeason: {
        url: '/associations/:associationID/seasons/:seasonID',
        method: 'GET',
    },
    createAssociationSeason: {
        url: '/associations/:associationID/seasons',
        method: 'POST',
    },
    updateAssociationSeason: {
        url: '/associations/:associationID/seasons/:seasonID',
        method: 'PUT',
    },
    deleteAssociationSeason: {
        url: '/associations/:associationID/seasons/:seasonID',
        method: 'DELETE',
    },
    getAssociationSeasonTeams: {
        url: '/associations/:associationID/seasons/:seasonID/seasonTeams',
        method: 'GET',
    },
    createAssociationSeasonTeam: {
        url: '/associations/:associationID/seasons/:seasonID/seasonTeams',
        method: 'POST',
    },
    deleteAssociationSeasonTeam: {
        url: '/associations/:associationID/seasons/:seasonID/teams/:teamID',
        method: 'DELETE',
    },

    /**
     *
     * Sports
     *
     **/

    getSports: {
        url: '/sports',
        method: 'GET',
    },
    getSport: {
        url: '/sports/:sportID',
        method: 'GET',
    },
    getEntityCategories: {
        url: '/sports/:sportID/entityCategories',
        method: 'GET',
    },
    getEntityCategory: {
        url: '/sports/:sportID/entityCategories/:entityCategoryID',
        method: 'GET',
    },

    /**
     *
     * Staff
     *
     **/

    getStaff: {
        url: '/:entityType/:entityID/staff',
        method: 'GET',
    },
    getStaffDetails: {
        url: '/:entityType/:entityID/staffDetails',
        method: 'GET',
    },
    getStaffSingle: {
        url: '/:entityType/:entityID/staff/:userID',
        method: 'GET',
    },
    getStaffDetailsSingle: {
        url: '/:entityType/:entityID/staff/:userID/details',
        method: 'GET',
    },
    updateStaff: {
        url: '/:entityType/:entityID/staff/:userID',
        method: 'PUT',
    },
    deleteStaff: {
        url: '/:entityType/:entityID/staff/:userID',
        method: 'DELETE',
    },
    getStaffInvites: {
        url: '/:entityType/:entityID/staffInvites',
        method: 'GET',
    },
    createStaffInvite: {
        url: '/:entityType/:entityID/staffInvites',
        method: 'POST',
    },

    /**
     *
     * Stats
     *
     **/

    getStats: {
        url: '/sports/:sportID/stats',
        method: 'GET',
    },
    getStat: {
        url: '/sports/:sportID/stats/:statID',
        method: 'GET',
    },

    /**
     *
     * Teams
     *
     **/

    getTeam: {
        url: '/teams/:teamID',
        method: 'GET',
    },
    createTeam: {
        url: '/users/:userID/teams',
        method: 'POST',
    },
    joinTeam: {
        url: '/users/:userID/teams',
        method: 'PUT',
    },
    updateTeam: {
        url: '/teams/:teamID',
        method: 'PUT',
    },
    deleteTeam: {
        url: '/teams/:teamID',
        method: 'DELETE',
    },
    archiveTeam: {
        url: '/teams/:teamID',
        method: 'PUT',
    },

    /**
     *
     * Tickets
     *
     **/

    getTicket: {
        url: '/tickets/:ticketID',
        method: 'GET',
    },
    acceptTicket: {
        url: '/tickets/:ticketID/accept',
        method: 'GET',
    },
    declineTicket: {
        url: '/tickets/:ticketID/decline',
        method: 'GET',
    },
    revokeTicket: {
        url: '/:entityType/:entityID/tickets/:ticketID/revoke',
        method: 'PUT',
    },
    resendTicket: {
        url: '/:entityType/:entityID/tickets/:ticketID/resend',
        method: 'PUT',
    },
};

export const prepareHeaders = async (headers: Headers, api: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionID = urlParams.get('session_id');
    const useCustomToken = localStorage.getItem(USE_CUSTOM_TOKEN);

    if (sessionID) {
        // Temporary JWT Token from search param
        headers.set('Authorization', `Bearer ${sessionID}`);
    } else if (useCustomToken === 'true') {
        // Persistant JWT Token from search param
        const customToken = localStorage.getItem(CUSTOM_TOKEN_ID);
        if (customToken) {
            headers.set('Authorization', `Bearer ${customToken}`);
        }
    } else {
        // Auth0 Token
        const { id_token } = await sec.getAccessTokenSilently()({
            detailedResponse: true,
        });

        if (id_token) {
            headers.set('Authorization', `Bearer ${id_token}`);
        }
    }

    return headers;
};
