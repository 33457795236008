import { useGetMessageSettingsQuery } from '../../api/messages';

import { useParams } from 'react-router-dom';
import { BaseEntityType } from '../../types/common';
import MessageSettingsForm from './MessageSettingsForm';

const MessageSettingsContainer = () => {
    const params = useParams();

    let entityType = BaseEntityType.users;
    let entityID = '';

    // Store entityType and entityID
    if ( params.teamID ){
        entityType = BaseEntityType.teams;
        entityID = params.teamID;
    } else if ( params.organisationID ){
        entityType = BaseEntityType.organisations;
        entityID = params.organisationID;
    } else if ( params.associationID ){
        entityType = BaseEntityType.associations;
        entityID = params.associationID;
    }

    const {data: messageSettingsResponse, isLoading} = useGetMessageSettingsQuery(
        { entityID, entityType },
        {
            skip: !entityID,
        }
    );

    const messageSettings = messageSettingsResponse?.data || undefined;

    if(isLoading){
        return <div>Loading...</div>;
    }

    return (
        <MessageSettingsForm
            messageSettings={messageSettings}
            entityType={entityType}
            entityID={entityID}
        />
    );
};

export default MessageSettingsContainer;
