import { useMemo, useState } from 'react';

import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';
import { Checkbox } from 'primereact/checkbox';

import { BaseEntityType, ERROR_TYPES, UserType } from '../../types/common';
import { useAddUserToGroupMutation } from '../../api/groups';
import { useGetEntityPlayersQuery } from '../../api/players';
import { useGetStaffDetailsQuery } from '../../api/staff';
import { GroupUser } from '../../types/groups';
import { uniqBy } from 'lodash';
import { UserDetails } from '../../types/user';
import { SelectButton } from 'primereact/selectbutton';
import ErrorDisplay from '../../components/ErrorDisplay';
import ListItem from '../../components/ListItem';
import List from '../../components/List';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    groupID: string;
    hiddenIDs?: GroupUser[];
    onError?: (error?: any) => void;
    onComplete?: (response: any) => void;
}

const AddToGroupForm = ({
    entityType,
    entityID,
    groupID,
    hiddenIDs,
    onError,
    onComplete,
}: Props) => {
    const [loading, setLoading] = useState(false);

    const [selected, setSelected] = useState<UserDetails[]>([]);
    const [filter, setFilter] = useState<UserType>(UserType.entityStaff);

    const { data: playersData, isLoading: loadingPlayers } =
        useGetEntityPlayersQuery(
            {
                teamID: entityID,
                cursor: '',
            },
            {
                skip: entityType !== BaseEntityType.teams,
            }
        );

    const { data: staffData, isLoading: loadingStaff } =
        useGetStaffDetailsQuery(
            {
                entityID,
                entityType,
                cursor: '',
            },
            {
                skip: entityType !== BaseEntityType.teams,
            }
        );

    const userList = useMemo(() => {
        let all = [...(playersData?.data || []), ...(staffData?.data || [])];

        all = all.filter(
            (user) =>
                !filter ||
                (filter === user.userType && !hiddenIDs) ||
                (hiddenIDs && !hiddenIDs.some((u) => u.userID === user.userID))
        );

        return uniqBy(all, (o) => o.userID);
    }, [playersData, staffData, hiddenIDs, filter]);

    const [addToGroup] = useAddUserToGroupMutation();

    const checkSelected = (user: UserDetails) =>
        selected.some((o) => o.userID === user.userID);

    const handleSelectionChange = (user: UserDetails) => {
        const isSelected = checkSelected(user);

        setSelected((prevStat) => {
            return isSelected
                ? prevStat.filter((o) => o.userID !== user.userID)
                : [...prevStat, user];
        });
    };

    const handleSubmit = () => {
        setLoading(true);

        if (selected && entityType && entityID) {
            const users: any[] = selected.map((o) => ({
                userID: o.userID,
                userType: o.userType,
            }));

            addToGroup({
                entityType,
                entityID,
                groupID,
                users,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response.error);
                        }
                    } else {
                        if (onComplete) {
                            // @ts-expect-error
                            onComplete(response.data.data);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);

            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    if (loadingPlayers || loadingStaff) {
        return <Loader />;
    }

    return (
        <div className="addto-group-form">
            <div className="addto-group-inputs">
                <SelectButton
                    value={filter}
                    options={[
                        {
                            label: `${
                                selected.filter(
                                    (u) => u.userType === UserType.entityStaff
                                ).length
                            } Staff`,
                            value: UserType.entityStaff,
                        },
                        {
                            label: `${
                                selected.filter(
                                    (u) => u.userType === UserType.entityPlayer
                                ).length
                            } Players`,
                            value: UserType.entityPlayer,
                        },
                    ]}
                    onChange={(e) => setFilter(e.value)}
                />

                {userList.length <= 0 ? (
                    <div style={{ padding: '2rem' }}>
                        <ErrorDisplay
                            errorType={ERROR_TYPES.empty}
                            hasReporting={false}
                            hasReturn={false}
                            title={`No ${
                                filter === UserType.entityPlayer
                                    ? 'Players'
                                    : filter === UserType.entityStaff
                                    ? 'Staff Members'
                                    : 'Users'
                            }`}
                        />
                    </div>
                ) : (
                    <List>
                        {userList.map((user) => (
                            <ListItem
                                key={user.userID}
                                onClick={() => !user.roles.some( ({roleID}) => roleID === 'teamsPending') && handleSelectionChange(user)}
                                type="link"
                                disabled={user.roles.some( ({roleID}) => roleID === 'teamsPending')}
                                start={
                                    <Checkbox
                                        inputId={user.userID}
                                        name="player"
                                        value={user.userID}
                                        checked={checkSelected(user)}
                                    />
                                }
                                title={`${user.firstName} ${user.lastName}`}
                            />
                        ))}
                    </List>
                )}
            </div>
            <div className="addto-group-footer">
                <div>{selected.length} selected</div>
                <RookieButton
                    label="Save"
                    onClick={handleSubmit}
                    disabled={loading || selected.length <= 0}
                    loading={loading}
                />
            </div>
        </div>
    );
};

export default AddToGroupForm;
