import { useMemo, useRef } from 'react';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

import RookieButton from '../../components/RookieButton';
import Icon from '../../components/Icon';

import { ResourceFolder } from '../../types/resources';

interface Props {
    crumbs: ResourceFolder[];
    onClick?: (folderID: string) => void;
    overflow?: boolean;
}

const FolderBreadCrumbs = ({ overflow, crumbs, onClick }: Props) => {
    const crumbPopover = useRef<Menu>(null);

    const showCount = 2;

    const breadCrumbProps = useMemo((): {
        home: MenuItem;
        model: MenuItem[];
    } => {
        const renderCrumb = (folder: ResourceFolder, active?: boolean) => {
            const folderName =
                folder.folderName === folder.entityID
                    ? 'All Files'
                    : folder.folderName;

            return active ? (
                <span className="p-menuitem-text ">{folderName}</span>
            ) : (
                <div
                    style={{ cursor: 'pointer' }}
                    className="p-menuitem-link"
                    onClick={() => onClick && onClick(folder.folderID)}
                >
                    <span className="p-menuitem-text ">{folderName}</span>
                </div>
            );
        };

        const getFullProps = () => {
            const home = { template: renderCrumb(crumbs[0]) };
            const model = crumbs.slice(1).map((folder, i) => ({
                template: renderCrumb(folder, i === crumbs.length - 1),
            }));

            return {
                home,
                model,
            };
        };

        const getCompactProps = () => {
            const visibleCrumbs = crumbs.slice(-showCount);
            const hiddenCrumbs = crumbs.slice(0, -showCount);
            const visibleCount = visibleCrumbs.length - 1;

            const menu = hiddenCrumbs.reverse().map(
                (folder): MenuItem => ({
                    id: folder.folderID,
                    template: (
                        <div className="p-menuitem-content">
                            <div
                                className="p-menuitem-link"
                                role="menuitem"
                                style={{ cursor: 'pointer' }}
                            >
                                <span className="p-menuitem-text">
                                    {folder.folderName}
                                </span>
                            </div>
                        </div>
                    ),
                    command: () => onClick && onClick(folder.folderID),
                })
            );

            const home = {
                template: (
                    <>
                        <RookieButton
                            onClick={(e) =>
                                crumbPopover.current &&
                                crumbPopover.current.toggle(e)
                            }
                            icon="more_horiz"
                            size="small"
                            severity="secondary"
                        />
                        <Menu model={menu} popup={true} ref={crumbPopover} />
                    </>
                ),
            };

            const model = visibleCrumbs.map((route, i) => ({
                template: renderCrumb(route, i === visibleCount),
            }));

            return {
                home,
                model,
            };
        };

        return overflow && crumbs.length > showCount + 1
            ? getCompactProps()
            : getFullProps();
    }, [crumbs, overflow, onClick]);

    return (
        <BreadCrumb
            {...breadCrumbProps}
            separatorIcon={(props) => <Icon {...props} name="chevron_right" />}
        />
    );
};

FolderBreadCrumbs.defaultProps = {
    overflow: true,
};

export default FolderBreadCrumbs;
