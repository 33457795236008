import { Params, matchPath } from 'react-router-dom';
import { Route, PathMatchWithRoute } from '../types/route';
import { Roles } from '../types/roles';
import { addLocale } from 'primereact/api';
import { BaseEntityType, EntityStructure } from '../types/common';
import { LicenceGroupInfo } from '../types/licences';
import { differenceInSeconds, formatDistanceToNow } from 'date-fns';

export const getEntityFromParam = (
    params: Params
): EntityStructure | undefined => {
    const { userID, teamID, organisationID, associationID } = params;

    if (userID) {
        return {
            entityType: BaseEntityType.users,
            entityID: userID,
        };
    } else if (teamID) {
        return {
            entityType: BaseEntityType.teams,
            entityID: teamID,
        };
    } else if (organisationID) {
        return {
            entityType: BaseEntityType.organisations,
            entityID: organisationID,
        };
    } else if (associationID) {
        return {
            entityType: BaseEntityType.associations,
            entityID: associationID,
        };
    } else {
        return undefined;
    }
};

/**
 * Return a base url for the notes either personal notes (/notes) or team notes (/t/team_id/notes).
 * Use this to navigate back from create or edit note component.
 * @param team
 * @returns {string}
 */
export const getNotesBaseUrl = (team: string) => {
    let url = '/notes';
    if (team && team !== '') {
        url = '/t/' + team + url;
    }

    return url;
};

/**
 * @desc Get entity title from first matching entity key
 * @param roles {object}
 * @param pathname {string}
 * @returns role {object || null}
 */
export const getActiveEntity = (roles: Roles, pathname: string) => {
    const urlSegment = pathname.split('/');
    const entityID = urlSegment[2];
    return roles.find((ent) => ent.entityID === entityID);
};

/**
 * A function to join two specified paths and replace superfluous "/" symbols.
 * @param paths
 */
function joinPaths(paths: string[]): string {
    return paths.join('/').replace(/\/\/+/g, '/');
}

/**
 * A function to map through the passed in route object and return matching parent and child routes.
 * @param definitions
 * @param locationPathname
 * @param parentPath
 */
export function findMatchedRoutes(
    definitions: Route[],
    locationPathname: string,
    parentPath: string = ''
): PathMatchWithRoute[] {
    const matches: PathMatchWithRoute[] = [];

    definitions.forEach((definition, index) => {
        const value = definition;
        const definedPath = value['path'];
        const ignoredPaths = ['/', '*'];

        if (Array.isArray(definedPath)) {
            definedPath.forEach((defPath) => {
                const pathPatternWithParent = joinPaths([parentPath, defPath]);

                const match = matchPath(
                    { path: pathPatternWithParent, end: false },
                    locationPathname
                );

                if (match && !ignoredPaths.includes(defPath)) {
                    matches.push({
                        ...match,
                        parentPath,
                        value,
                    });

                    if (value['children']) {
                        const nestedMatches = findMatchedRoutes(
                            value['children'],
                            locationPathname,
                            pathPatternWithParent
                        );

                        matches.push(...nestedMatches);
                    }
                }
            });
        } else {
            const pathPatternWithParent = joinPaths([parentPath, definedPath]);

            const match = matchPath(
                { path: pathPatternWithParent, end: false },
                locationPathname
            );

            if (match && !ignoredPaths.includes(definedPath)) {
                matches.push({
                    ...match,
                    value,
                    parentPath,
                });

                if (value['children']) {
                    const nestedMatches = findMatchedRoutes(
                        value['children'],
                        locationPathname,
                        pathPatternWithParent
                    );

                    matches.push(...nestedMatches);
                }
            }
        }
    });

    return matches;
}

export const auDateLocale = () =>
    addLocale('en-au', {
        firstDayOfWeek: 1,
        dayNames: [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ],
        dayNamesShort: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
        dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        monthNamesShort: [
            'jan',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec',
        ],
    });

export const toISOStringWithTimezone = (date: Date | string) => {
    date = date instanceof Date ? date : new Date(date);

    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
    return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        diff +
        pad(tzOffset / 60) +
        ':' +
        pad(tzOffset % 60)
    );
};

export const toISOStringWithoutTimezone = (date: Date | string) => {
    date = date instanceof Date ? date : new Date(date);

    return date.toISOString().split('T')[0];
};

export const toISOString = (date: Date | string) => {
    date = date instanceof Date ? date : new Date(date);

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16);
};

//  Convert a "dd/MM/yyyy" format into a Date object
export const convertToDate = (dateString: string, timeString = '') => {
    const d = dateString.split('/');
    const mmddyy = d[2] + '/' + d[1] + '/' + d[0];

    return new Date(`${mmddyy} ${timeString}`);
};

export const timestampToDate = (timestamp: number) => {
    return new Date(timestamp * 1000);
};

export const permissionChecker = (
    pathname: string,
    roles: Roles = [],
    permission: string
) => {
    const urlSegment = pathname.split('/');
    const entityID = urlSegment[2];
    const role = roles.find((ent) => ent.entityID === entityID);

    return role && role.permissions.includes(permission);
};

export const periodSuffix = function (period: number) {
    if (period > 3 && period < 21) return 'th';
    switch (period % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const getFirstChars = (str: string, limit: number) => {
    if (str) {
        const chars = str
            .split(' ')
            .map((word) => word.charAt(0))
            .join('');

        return limit ? chars.slice(0, limit) : chars;
    } else {
        return '';
    }
};

export const calculateEqualGameTime = (
    periods: number,
    periodLength: number,
    maxPlayers: number,
    maxPlayersOnField: number
) => {
    const totalGameTime = periods * periodLength;

    return (
        Math.floor((totalGameTime * maxPlayersOnField) / maxPlayers) /
        totalGameTime
    );
};

export const calculatePercentageGameTime = (stats: any) => {
    if (!stats.total__time_period) {
        return null;
    }

    if (
        stats.Field &&
        stats.Bench &&
        stats.Injury &&
        stats.Assessment &&
        stats.Disciplinary &&
        stats.Opposition
    ) {
        const ttf = Number(stats.Field) + Number(stats.Bench);
        const ttp =
            Number(stats.total__time_period) -
            (Number(stats.Injury) +
                Number(stats.Assessment) +
                Number(stats.Opposition));
        return ttf / ttp;
    } else if (stats.total__time_on_field) {
        return (
            Number(stats.total__time_on_field) /
            Number(stats.total__time_period)
        );
    }
};

export const formatTime = (seconds: number, expanded?: boolean): string => {
    const date = new Date(seconds * 1000);
    const hours = date.getUTCHours();
    const mins = date.getUTCMinutes();
    const secs = date.getUTCSeconds();

    if (expanded) {
        if (isNaN(seconds)) {
            return '00:00';
        }

        const hourString = hours > 0 ? `${hours} hr ` : '';
        const minString = mins > 0 ? `${mins} min` : '';
        const secString = secs > 0 ? `${secs} sec` : '';

        if (hours > 0) {
            return `${hourString} ${minString} ${secString}`;
        } else {
            return `${minString} ${secString}`;
        }
    } else {
        if (isNaN(seconds)) {
            return '00:00';
        }

        const hh = hours.toString();
        const mm = mins.toString().padStart(2, '0');
        const ss = secs.toString().padStart(2, '0');

        if (parseInt(hh) > 0) {
            return `${hh}:${mm}:${ss}`;
        }
        return `${mm}:${ss}`;
    }
};

export const formatPercentage = (value: number, decimals = 0) => {
    return Number(value).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: decimals,
    });
};

export const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const isValidToken = (payload: any) => {
    if (!payload) return false;

    const now = new Date();
    const expiry = timestampToDate(payload.exp);

    return expiry > now;
};

export const getNiceRole = (roleName: string) => {
    return roleName.replace(/teams|organisations|associations/gi, '');
};

export const getNiceEntityType = (entityType: string) => {
    var mapObj = {
        teams: 'Team',
        organisations: 'Organisation',
        associations: 'Association',
    };

    return entityType.replace(
        /teams|organisations|associations/gi,
        (matched) => mapObj[matched as keyof typeof mapObj]
    );
};

export const isInAppSubscription = (
    licenceGroup: LicenceGroupInfo
): boolean => {
    return !!licenceGroup.transactionID && licenceGroup.transactionID !== '';
};

export const getTimeAgo = (
    date: Date | string,
    addSuffix?: boolean,
    includeSeconds?: boolean
) => {
    date = date instanceof Date ? date : new Date(date);
    const diff = differenceInSeconds(new Date(), date);

    if (diff < 30) {
        return 'now';
    } else {
        const options = {
            addSuffix: addSuffix,
            includeSeconds: includeSeconds,
        };
        return formatDistanceToNow(date, options);
    }
};

export const formatFileSize = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
