import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { associationsApi } from './api/associations';
import { collectionsApi } from './api/collections';
import { coreApi } from './api/core';
import { eventsApi } from './api/events';
import { gamesApi } from './api/games';
import { groupsApi } from './api/groups';
import { licencesApi } from './api/licences';
import { messagesApi } from './api/messages';
import { notesApi } from './api/notes';
import { notificationsApi } from './api/notifications';
import { organisationsApi } from './api/organisations';
import { playersApi } from './api/players';
import { postsApi } from './api/posts';
import { profilesApi } from './api/profiles';
import { reportsApi } from './api/reports';
import { resourcesApi } from './api/resources';
import { seasonsApi } from './api/seasons';
import { sportsApi } from './api/sports';
import { staffApi } from './api/staff';
import { statsApi } from './api/stats';
import { teamsApi } from './api/teams';
import { ticketsApi } from './api/tickets';

export const store = configureStore({
    reducer: {
        [associationsApi.reducerPath]: associationsApi.reducer,
        [collectionsApi.reducerPath]: collectionsApi.reducer,
        [coreApi.reducerPath]: coreApi.reducer,
        [eventsApi.reducerPath]: eventsApi.reducer,
        [gamesApi.reducerPath]: gamesApi.reducer,
        [groupsApi.reducerPath]: groupsApi.reducer,
        [licencesApi.reducerPath]: licencesApi.reducer,
        [messagesApi.reducerPath]: messagesApi.reducer,
        [notesApi.reducerPath]: notesApi.reducer,
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        [organisationsApi.reducerPath]: organisationsApi.reducer,
        [playersApi.reducerPath]: playersApi.reducer,
        [postsApi.reducerPath]: postsApi.reducer,
        [profilesApi.reducerPath]: profilesApi.reducer,
        [reportsApi.reducerPath]: reportsApi.reducer,
        [resourcesApi.reducerPath]: resourcesApi.reducer,
        [seasonsApi.reducerPath]: seasonsApi.reducer,
        [sportsApi.reducerPath]: sportsApi.reducer,
        [staffApi.reducerPath]: staffApi.reducer,
        [statsApi.reducerPath]: statsApi.reducer,
        [teamsApi.reducerPath]: teamsApi.reducer,
        [ticketsApi.reducerPath]: ticketsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            associationsApi.middleware,
            collectionsApi.middleware,
            coreApi.middleware,
            eventsApi.middleware,
            gamesApi.middleware,
            groupsApi.middleware,
            licencesApi.middleware,
            messagesApi.middleware,
            notesApi.middleware,
            notificationsApi.middleware,
            organisationsApi.middleware,
            playersApi.middleware,
            postsApi.middleware,
            profilesApi.middleware,
            reportsApi.middleware,
            resourcesApi.middleware,
            seasonsApi.middleware,
            sportsApi.middleware,
            staffApi.middleware,
            statsApi.middleware,
            teamsApi.middleware,
            ticketsApi.middleware,
        ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
