import { toUpper } from 'lodash';

import { BaseEntityType } from '../types/common';

import { Avatar } from 'primereact/avatar';
import { useGetStaffDetailsSingleQuery } from '../api/staff';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    userID: string;
}

const StaffCell = ({ entityID, entityType, userID }: Props) => {
    const { data: staffData } = useGetStaffDetailsSingleQuery({
        entityID,
        entityType,
        userID,
    });

    if (!staffData?.data) {
        return 'Staff';
    }

    return (
        <div className="detail-cell">
            <Avatar
                image={staffData?.data.picture}
                label={toUpper(
                    `${staffData?.data.firstName.slice(
                        0,
                        1
                    )}${staffData?.data.lastName.slice(0, 1)}`
                )}
            />
            <span>
                {staffData?.data.firstName} {staffData?.data.lastName}
            </span>
        </div>
    );
};

export default StaffCell;
